import React, {useRef} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'

// My Components
import Layout from '../../../../layouts'
import Grid from '../../../../components/elements/grid'
import { Body, SizedText } from '../../../../components/elements/typography'
import Modal from '../../../../components/molocules/modal/modal'
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react';

import "../../../../theme/styles/video.css"

const CardActivationDemo = ({ }) => {

  const modalRef1 = useRef();

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          layout="fullWidth"
          aspectRatio={3 / 1}
          alt=""
          src="../../../../images/resources/product-tips/digital-banking-tips.jpg"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
            boxShadow: `inset 0px 11px 8px -10px rgba(0,0,0,0.4), inset 0px -11px 8px -10px rgba(0,0,0,0.4)`,
          }}
        >
          <div css={{textAlign: `center`}}>
          <SizedText as="p" 
            smallSize="1.424" 
            largeSize="2.027" 
            styleOverrides={{  
              display: `inline-block`,
              fontFamily: `Proxima-nova`, 
              fontWeight: `300 !important`, 
              borderBottom: `2px solid #000`, 
              paddingBottom: `0.25rem`, 
              marginBottom: `2rem`, 
              width: `max-content !important`, 
              '@media (min-width: 680px)': {
                borderBottom: `3px solid #000`, 
                paddingBottom: `0.5rem`, 
              },
            }}>
              Helpful Tips
            </SizedText>
            <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, 'span': {display: `block`, lineHeight: `1.25`, padding: `0.25rem 0 0 0`, hyphens: `auto`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">Digital Banking</SizedText>
          </h1>
          </div>
        </div>
      </div>

      <Grid.FullWidthSection css={{padding: `6rem 0`, alignItems: `center`}}>
        <Grid.Block start={["3","3","4","4","3","4"]} span={["10","10","8","8","6", "3"]}>
          <div css={{position: `relative`,}}>
            <div css={{position: `relative`, backgroundColor: `#000`}}>
              <video css={{
                position: `absolute`,
                zIndex: `0`,
                objectFit: `cover`,
                width: `77%`,
                marginLeft: `11.5%`,
                marginTop: `6%`,
                '&::-webkit-media-controls': {
                    display: `none !important`,
                }
              }} autoPlay muted playsInline loop>
                <source src="https://tcb-website-videos.s3.amazonaws.com/cards.mp4"  />     
              </video>
            </div>
              <StaticImage src="../../../../images/phone-overlay.png" alt="" />
          </div>
        </Grid.Block>
        <Grid.Block start="7" span="5">
          <h2 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, 'span': {display: `block`, lineHeight: `1.25`, padding: `0.25rem 0 0 0`, hyphens: `auto`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="2.887">Activate Your Debit Card With Digital Banking</SizedText>
          </h2>
          <Body>
            <ol css={{marginBottom: `2rem`}}>
              <li>Log into you digital banking acount.</li> 
              <li>Tap the Menu icon at the bottom of the screen to open the full menu.</li>
              <li>Tap on Manage Cards to open the card controls screen.</li>
              <li>Tap on the card that says “Activation Required” in yelow writing.</li>
              <li>Tap “Activate” at the bottom of the screen.</li>
              <li>The activation process will take a few seconds, and then you will receive a “Card Sucesfuly Activated” mesage. Tap “Close Window”.</li>
              <li>Use your card to make purchases and get money anywhere you see the MasterCard logo.</li>
            </ol>
          </Body>
          
          <div onClick={() => modalRef1.current.openModal()} css={{
            display: `flex`,
            alignItems: `center`,
            cursor: `pointer`,
            '& .text': { position: `relative`, overflow: `hidden`,},
            '& .text::after': {
              content: `''`,
              position: `absolute`,
              bottom: `0`,
              left: `0`,
              width: `0%`,
              height: `0.1em`,
              backgroundColor: `#000`,
              opacity: `0`,
              transform: `translate3d(-100%, 0, 0)`,
              transition: `opacity 700ms, transform 300ms`,
            },
            '&:hover': {
              '& .videoPortHole': { transform: `scale(1.5)`, },
              '& .text::after': { transform: `translate3d(0, 0, 0)`, width: `100%`, opacity: `100`,}
            }
          }}>
            <div className="videoPortHole" css={{
              backgroundColor: 'blue',
              height: `3.5rem`,
              width: `3.5rem`,
              borderRadius: `50%`,
              border: `1px solid gray`,
              transition: `all .2s ease-in-out`,
              overflow: `hidden`,
            }}>
              <video css={{
                position: `absolute`,
                zIndex: `0`,
                objectFit: `cover`,
                height: `3.5rem`,
              width: `3.5rem`,
              borderRadius: `50%`
              }} autoPlay muted playsInline loop>
                <source src="http://peach.themazzone.com/durian/movies/sintel-1024-surround.mp4" />
              </video>
            </div>
            <div css={{paddingLeft: `1.25rem`, lineHeight: `1`}}>
              <SizedText className="text" as="span" smallSize="2.281" largeSize="1.424">Watch the Video</SizedText>
            </div>
          </div>
          
          <Modal ref={modalRef1}>
            <div css={{ width: `960px`, height: `408px`}}>                
              <Player autoPlay poster="/assets/poster.png">
                <source src="http://peach.themazzone.com/durian/movies/sintel-1024-surround.mp4" />
                <source src="http://mirrorblender.top-ix.org/movies/sintel-1024-surround.mp4" />

                <ControlBar>
                  <ReplayControl seconds={10} order={1.1} />
                  <ForwardControl seconds={30} order={1.2} />
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                  <VolumeMenuButton disabled />
                </ControlBar>
              </Player>  
              </div>
          </Modal>
        {/*  <div css={{marginTop: `2rem`}}>
            <a href="https://apps.apple.com/us/app/the-claxton-bank-mobile/id994317983?ign-mpt=uo%3D4"><StaticImage src="../../images/products/digital-banking/app-store-badge.png" alt="Download on the App Store" height="50" width="168" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.csiweb.digitalbanking.bk0704"><StaticImage src="../../images/products/digital-banking/google-play-badge.png" alt="Get it on Google Play" height="50" width="168"/></a><br />
            </div> */}
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}

export default CardActivationDemo